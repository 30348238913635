.banner {
  padding: 20px 0;
  background-color: #373347;
  
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-size: 30px;
    font-weight: 600;
  }
  .btn {
    height: 40px;
    width: 200px;
  }

  @media only screen and (max-width: 700px) {
    .title {
      font-size: 20px;
    }
    .container {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 15px;
    }
    .btn {
      width: 80%;
    }
  }
}
