.appointment-user-details-wrapper {
  padding-top: 120px;

  .appointment-user-details{
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: 550px)  {
      flex-direction: column;
    }
  }

  .btn--outlined-primary {
    margin-left: 0;
    margin-bottom: 20px;
    min-width: 100px;
    height: 30px;
  }

  .appointment-details {
    flex: 1;
    margin-right: 100px;
    @media only screen and (max-width: 550px)  {
      margin-right: 0;
      margin-bottom: 20px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      padding-bottom: 10px;
      padding-top: 10px;
    }

    &__date {
      font-size: 18px;
      border-bottom: 1px solid white;
      @media only screen and (max-width: 550px)  {
        border-bottom: none;
      }
    }
    &__appointment-type {
      font-size: 16px;
      margin: 10px 0 5px;
    }
    &__time,
    &__appointment-duration {
      font-size: 14px;
      opacity: .7;
    }
  }
  .user-details {
    flex: 2;

    &__title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  .user-detail {
    margin-bottom: 20px;
    &__title {
      font-size: 14px;
    }
    &__error {
      color: rgb(185, 68, 68);
      font-size: 14px;
    }
    .cap {
      text-transform: capitalize;
    }
    .error {
      border: 2px solid rgb(185, 68, 68);
      background: rgba(185, 68, 68, .1);
      color: white;
    }
    input {
      outline: none;
      border: none;
      border-radius: 4px;
      padding: 5px 15px;
      height: 35px;
      width: 100%;
      max-width: 300px;
      opacity: .8;
      font-size: 14px;
      @media only screen and (max-width: 550px)  {
        max-width: unset;
      }
    }
  }
  .btn--primary {
    position: relative;
    margin-top: 30px;
    width: 100%;
    max-width: 280px;
    height: 30px;
    box-shadow: 0px 4px 12px rgba(#111111, .2);
    @media only screen and (max-width: 550px)  {
      max-width: unset;
      padding: 5px 0;
    }
  }
}
