.list-with-cards {
  margin-top: 50px;
  min-height: 400px;
  position: relative;
  margin-bottom: 100px;

  h2 {
    margin: 0;
  }
  p {
    margin: 0;
    margin-left: 30px;
  }
  .mobile-list {
    display: none;
  }
  .title {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 3px;
    margin-bottom: 35px;
  }
  .list {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .card {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 220px;
    padding: 25px 30px;
    background-color: #454057;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(#111111, .2);


    &__icon {
      width: 80px;
      height: auto;
      margin-bottom: 20px;
    }
    &__title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 700px) {
    
  }
  @media only screen and (max-width: 550px) {
    
  }
}
