.appointment-list {
  padding-top: 120px;
  display: flex;
  flex-wrap: wrap;
  gap: 80px;

  @media only screen and (max-width: 1014px)  {
    justify-content: center;
  }
}
.appointment-card {
  width: 280px;
  padding: 25px 30px;
  background-color: #454057;
  border-radius: 4px;

  .title {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .duration {
    font-size: 12px;
    opacity: .5;
    margin-bottom: 10px;
  }
  .description {
    font-size: 14px;
    opacity: .8;
    margin-bottom: 10px;
  }

  .btn {
    width: 100%;
    padding: 0;
    height: 35px;
    font-size: 14px;
    box-shadow: 0 2px 8px rgba(#111111, .2);

    &:hover {
      box-shadow: none;
    }
  }
}
.bg-line {
  top: 35%;
}
@media only screen and (max-width: 550px) {
  .bg-line {
    height: 600px;
  }
}
