
:root {
  --secondary-color: #D2B688;
  --primary-color: #211D33;
  --bg-color: #373347;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
svg {
  overflow: visible;
  position: absolute;
}
p {
  margin: 0;
}
.container{
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 1000px) {
  .container{
    padding: 0 10px;
  }
}

img {
  height: 100%;
  width: 100%;
}

button {
  all: unset;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  transition: all ease .3s;
  position: relative;

  .btn-text {
    margin-right: 15px;
  }

  .btn-arrow {
    position: absolute;
    right: 15px;
    width: 15px;
    transition: right ease .3s;
  }
  &:hover {
    .btn-arrow{
      right: 10px;
    }
  }
}
.btn--primary {
  background-color: #D2B688;
  
  &:hover {
    background-color: darken(#D2B688, 10)
  }
}
.btn--outlined-primary {
  border: 1px solid #D2B688;

  &:hover {
    background-color: #D2B688;
  }
}
.bg-line {
  position: absolute;
  width: 100%;
  height: 300px;
  background-color: #373347;
  z-index: -1;
  top: -30px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#211D33, .85);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
