.appointment-time-selector{
  padding-top: 120px;
  
  .appointment-details {
    max-width: 33%;
    @media only screen and (max-width: 800px)  {
      max-width: unset;
    }
  }
  .title {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .duration {
    font-size: 12px;
    opacity: .5;
    margin-bottom: 10px;
  }
  .description {
    font-size: 14px;
    opacity: .8;
    margin-bottom: 10px;
  }

  .dates {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;

    @media only screen and (max-width: 800px)  {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
  }
  .selected-date {
    color: #FFFFFF;
    font-size: 16px;
    border-bottom: 1px solid #FFFFFF;
  }
  .available-dates__list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
  }
  .time {
    border: 1px solid #D2B688;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all ease .3s;

    &:hover {
      opacity: .7;
    }

    &.selected {
      background-color: #D2B688;
      color: #373347;
    }
  }

  .btn {
    min-width: 150px;
    height: 30px;
    box-shadow: 0px 4px 12px rgba(#111111, .2);
    margin-left: auto;

    &.hidden {
      opacity: 0;
    }
  }
  .btn--outlined-primary {
    margin-left: 0;
    margin-bottom: 20px;
    min-width: 100px;

  }

  .date-selector {
    color: white;
    .MuiPickerStaticWrapper-content {
      background-color: transparent;
    }
    .MuiTypography-root, 
    .MuiPickersArrowSwitcher-button,
    .MuiPickersCalendarHeader-switchViewButton,
    .MuiDayPicker-weekDayLabel,
    .MuiPickersDay-root,
    .MuiPickersDay-root {
      color: white;
    }
    .MuiPickersDay-root {
      background-color: #373347;

      &.Mui-selected {
        background-color: #D2B688;
      }
      &.Mui-disabled {
       opacity: .25;
      }
      &.MuiPickersDay-hiddenDaySpacingFiller {
        opacity: 0;
      }
      &.MuiPickersDay-today {
        border: 1px solid white;
      }

      &:hover {
        background-color: rgba(#D2B688, .5);
      }
    }
    .PrivatePickersYear-yearButton{
      &.Mui-selected {
        background-color: #D2B688;
      }
      &.Mui-disabled {
        color: rgba(#FFFFFF, .3);
      }

    }

    .MuiDialogActions-root,
    .MuiPickersToolbar-penIconButton,
    .MuiTypography-overline {
      display: none;
    }
    
  }
}
