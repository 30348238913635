.popup {
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 150px;
  background: #373347;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(#111111, .2);


  &__text {
    font-size: 16px;
  }
  .btn {
    min-width: 150px;
  }
}
