.header {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  
  .bg-image {
    object-fit: cover;
    opacity: .3;

    &.blurred{
      object-fit: cover;
    }
  }
  &__bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #211D33;
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  &__scroll {
    font-size: 10px;
    margin-top: 25vh;
    display: flex;
    justify-content: center;
    cursor: default;
  }
  &__scroll-text {
    display: inline-block;
    transform: rotateZ(-90deg);
  }
  &__arrow {
    height: 60px;
    width: 20px;
    object-fit: contain;
  }

  .texts-wrapper {
    max-width: 450px;
    padding-top: 20vh;
  }
  .btn {
    font-size: 16px;
    width: 200px;
    height: 30px;
  }
  
  h1 {
    margin: 0;
    font-size: 50px;
    font-weight: 500;
  }
  @media only screen and (max-width: 700px) {
    h1 {
      font-size: 40px;
    }
    .texts-wrapper {
      max-width: 330px;
    }
  }
}

