.navbar {
  position: fixed;
  z-index: 10;
  width: 100%;
  transition: background-color ease .3s;
  padding: 20px 0;

  
  .buttons {
    display: flex;
    align-items: center;
  }
  .logo {
    cursor: pointer;
    height: 50px;
    img {
      width: unset;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
  }
  .btn {
    width: 200px;
    min-width: 150px;
    height: 30px;
    box-shadow: 0px 4px 12px rgba(#111111, .2);
  }
  &--colored {
    background: rgba(#211D33, .99)

  }
  &--transparent {
    background-color: transparent;
  }

  &__button {
    font-size: 16px;
    font-weight: 400;
    margin: 0 15px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    padding: 0.2em 0;

    &:hover {
      color: #D2B688;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: #D2B688;
      opacity: 1;
      transform: scale(0);
      transform-origin: center;
      transition: opacity 300ms, transform 300ms;
    }
    
    &:hover::after,
    &:focus::after{
      transform: scale(1);
    }
  }
  
  .mobile-menu-icon {
    display: none;
    z-index: 11;
    div {
      width: 30px;
      height: 2px;
      background: white;
      margin-bottom: 8px;
      transition: all ease .3s;
      transform-origin: 1px;
      
      &:first-child {
        transform: rotate(0);
      }
      &:nth-child(2) {
        opacity: 1;
        transform: translateX(0);
      }
      &:nth-child(3) {
        transform: rotate(0);
      }
      &.open {
        &:first-child {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translateX(20px);
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .sidebar {
    background: #454057;
    position: absolute;
    width: 300px;
    height: 100vh;
    right: -100vw;
    transition: all ease .3s;
    z-index: 10;
    top: -10px;

    &.open {
      display: block;
      right: 0;
    }

    &-buttons {
      padding: 50px 10px;
      text-align: center;
    }
    .navbar__button {
      font-size: 18px;
      margin-bottom: 15px;
    }
    .btn {
      margin: 0 auto;
      width: 200px;
    }
  }

  @media only screen and (max-width: 800px) {
    .buttons {
      display: none;
    }
    .mobile-menu-icon {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  @media only screen and (max-width: 1000px) {
    padding: 10px 0;
    
  }
}
