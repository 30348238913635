.contacts {
  margin-bottom: 100px;
  width: 100%;
  h2 {
    margin: 0;
  }
  .title {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 3px;
    margin-bottom: 35px;
  }
  .contacts__list {
    display: flex;
    gap: 50px;
  }
  .contact {
    display: flex;
    align-items: center;

    &__icon {
      width: 30px;
      height: 30px; 
      margin-right: 10px;
    }
    &__texts {
      display: flex;
      flex-direction: column;
    }
    &__text {
      font-size: 16px;
      color: white;
      text-decoration: none;
    }
  }
  @media only screen and (max-width: 820px) {
    .contacts__list {
      flex-direction: column;
      padding-left: 30px;
    }
    .contact__text {
      font-size: 14px;
    }
  }
}
