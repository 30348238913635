.footer {
  padding-top: 50px;
  margin-top: auto;
  margin-bottom: 50px;
  width: 100%;
  &__top {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 430px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  &__disclaimer {
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
  }
  .texts {
    display: flex;
    flex-direction: column;
  }
  .name {
    font-size: 20px;
    font-weight: 600;
    margin:  0 0 30px 0;
    letter-spacing: 3px;
  }
  .text {
    margin-bottom: 8px;
  }
  .logo {
    height: 120px;
    @media only screen and (max-width: 430px) {
      width: 120px;
    }
  }

  @media only screen and (max-width: 700px) {
    .logo {
      height: 100px;
    }
  }
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
