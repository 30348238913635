.text-component-wrapper {
  position: relative;
  .photo {
    height: 100%;
    min-width: 320px;
    max-width: 500px;
    position: absolute;
    display: flex;
    align-items: center;
    &-img {
      object-fit: cover;
      max-height: 900px;
    }
  }
  .bg-color {
    position: absolute;
    background: #E4D7C6;
    width: 100%;
    height: 100%;
  }
  .text-component {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
    color: #211D33;
    h2 {
      margin: 0;
    }
    .title {
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 3px;
      margin-bottom: 25px;
    }
    .wrapper {
      display: grid;
      grid-template-columns: .5fr 2fr;
      font-size: 14px;
      
    }
    .text {
      text-align: justify;
      padding-left: 30px;
      font-size: 14px;
    }

    @media only screen and (max-width: 1500px) {
      .wrapper {
        grid-template-columns: 1fr 2fr;
      }
    }
    @media only screen and (max-width: 1300px) {
      .wrapper {
        grid-template-columns: 1fr 1fr;
      }
    }
    @media only screen and (max-width: 1050px) {
      .wrapper {
        grid-template-columns: 1.1fr 1fr;
      }

    }
    @media only screen and (max-width: 900px) {
      .wrapper {
        grid-template-columns: .7fr 1fr;
      }
    }
    @media only screen and (max-width: 700px) {
      .wrapper {
        grid-template-columns: 0 1fr;
      }
      .text {
        padding: 0;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .photo {
      min-width: auto;
      &-img {
        height: 400px;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .photo {
      position: relative;
      max-height: 500px;
      overflow: hidden;
      max-width: unset;
      &-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

}
